import {notification} from "antd";
import secureLocalStorage from "react-secure-storage";

export function showMessage(type, message, title) {
    if (type === 'success'){
        notification.success({
            placement:'bottomRight',
            message: title ? title : 'Success',
            description: message
        })
    }else{
        notification.error({
            placement:'bottomRight',
            message: title ? title : 'Error',
            description: message
        })
    }

}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const getUserToken = () => secureLocalStorage.getItem('token')

export const getCurrency = () => secureLocalStorage.getItem('user')?.currency

export const getUserDetail = () => secureLocalStorage.getItem('user')

export let currencyFormatter = (num) => {
    let split = "";
    if (num) {
        split = parseFloat(num).toFixed(2).toString().split(".");
        split[0] = split[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        split = split.join(".");

        return split === 0 ? 0 : split;
    }else{
        return 0;
    }
};

export const logout = () => {
    secureLocalStorage.removeItem('token');
    secureLocalStorage.removeItem('user');
    window.location.href = '/';
}
