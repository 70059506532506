import React from "react";
import {getCurrency} from "../util/util";

export const TransactionRow = (props) => {
  return (
      <tr>
          <td>
              <div className="row align-items-center">
                  <div className="col-3">
                      <img src={props.transaction.category?.icon} alt={props.transaction.id} height='30px' width='30px'/>
                  </div>
                  <div className="col-9">
                    <p className="mb-0">{props.transaction.title}</p>
                      <small className={`mb-0 ${props.transaction.type === 'expense' ? 'text-red':'text-green'}`}>{props.transaction.type}</small>
                  </div>
              </div>
          </td>
          <td>
              <p className={`mb-0 ${props.transaction.type === 'expense' ? 'text-red':'text-green'}`}>{`${getCurrency()}${props.transaction.amount}`}</p>
          </td>
          <td>
              <p className="mb-0">{props.transaction.date}</p>
          </td>
      </tr>
  )
}