import React from "react";
import {Doughnut} from "react-chartjs-2";

export const PieChart = ({ pieData }) => {

    const labels = ["Income", "Expense"];
    const data = {
        labels: labels,
        datasets: [
            {
                label: "Total",
                backgroundColor: ["#3380FD", "#F76C6C"],
                borderColor: ["#E8F0FE", "#ffc5d4"],
                data: [pieData?.income, pieData?.expenses],
            },
        ],
    };

  return <Doughnut data={data} />
}