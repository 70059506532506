import React from "react";
import {TransactionRow} from "../Snippets/TransactionRow";
import noTransactionImage from "../assets/images/no-payment.png";

export const Transactions = ({ transactions }) => {
    if (transactions.length > 0){
        return (
            <table className="table">
                <thead>
                <tr>
                    <th scope="col">Transaction</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date</th>
                </tr>
                </thead>
                <tbody>
                {transactions.map(transaction => <TransactionRow transaction={transaction} key={transaction.id}/>)}
                </tbody>
            </table>
        )
    }else{
        return (
            <div align='center'>
                <img src={noTransactionImage} alt="no transaction" style={{height:"30vh"}}/>
                <h4 className="">No transactions found!</h4>
            </div>
        )
    }

}