import React from "react";
import { Route, Routes } from 'react-router-dom'
import {Template} from "../Components/Template";
import {Home} from "../Pages/Home/Home";
import {TransactionPage} from "../Pages/Transaction/Transactions";
import {ProfilePage} from "../Pages/Profile/ProfilePage";


export const AuthenticatedRoutes = () => {
    return (
        <Template>
            <Routes>
                {/* Define routes for authenticated users */}
                <Route path="/" element={<Home />} />
                <Route path="/transactions" element={<TransactionPage />} />
                <Route path="/profile" element={<ProfilePage />} />
            </Routes>
        </Template>
    )
}