import React from "react";
import {getUserDetail, logout} from "../util/util";
import Avatar from "react-avatar";
import {serverRequest} from "../util/server_request";
import {Link} from "react-router-dom";

export const Template = (props) => {

    function signOut() {
        serverRequest('POST', 'logout').then(result => {
            logout()
        })
    }

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">

                <div className="d-flex align-items-center justify-content-between">
                    <a href="#" className="logo d-flex align-items-center">
                        <img src="assets/img/logo.png" alt="" />
                            <span className="d-none d-lg-block">Xpensave</span>
                    </a>
                    <i className="bi bi-list toggle-sidebar-btn"/>
                </div>


                <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">

                        <li className="nav-item d-block d-lg-none">
                            <a className="nav-link nav-icon search-bar-toggle " href="#">
                                <i className="bi bi-search"/>
                            </a>
                        </li>

                        {/*<li className="nav-item dropdown">

                            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                <i className="bi bi-bell"/>
                                <span className="badge bg-primary badge-number">4</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                <li className="dropdown-header">
                                    You have 4 new notifications
                                    <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-exclamation-circle text-warning"/>
                                    <div>
                                        <h4>Lorem Ipsum</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>30 min. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider" />
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-x-circle text-danger"/>
                                    <div>
                                        <h4>Atque rerum nesciunt</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>1 hr. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-check-circle text-success"/>
                                    <div>
                                        <h4>Sit rerum fuga</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>2 hrs. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>

                                <li className="notification-item">
                                    <i className="bi bi-info-circle text-primary"/>
                                    <div>
                                        <h4>Dicta reprehenderit</h4>
                                        <p>Quae dolorem earum veritatis oditseno</p>
                                        <p>4 hrs. ago</p>
                                    </div>
                                </li>

                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li className="dropdown-footer">
                                    <a href="#">Show all notifications</a>
                                </li>

                            </ul>

                        </li>*/}

                        <li className="nav-item dropdown pe-3">

                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <Avatar color={Avatar.getRandomColor('sitebase', ['#0987BC', '#095575'])} name={getUserDetail()?.name} round={true} size={40} className='shadow-sm font-weight-bold'/>
                                    <span className="d-none d-md-block dropdown-toggle ps-2">{getUserDetail()?.name}</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                {/*<li>*/}
                                {/*    <hr className="dropdown-divider"/>*/}
                                {/*</li>*/}

                                <li>
                                    <Link className="dropdown-item d-flex align-items-center" to='/profile'>
                                        <i className="bi bi-person"/>
                                        <span>Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <a className="dropdown-item d-flex align-items-center" href='#' onClick={() => signOut()}>
                                        <i className="bi bi-box-arrow-right"/>
                                        <span>Sign Out</span>
                                    </a>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>
            </header>

            <aside id="sidebar" className="sidebar">

                <ul className="sidebar-nav" id="sidebar-nav">

                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/">
                            <i className="bi bi-grid"/>
                            <span>Dashboard</span>
                        </Link>
                    </li>

                    <li className="nav-heading">More</li>

                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/transactions">
                            <i className="bi bi-credit-card"/>
                            <span>Transactions</span>
                        </Link>
                    </li>

                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">*/}
                    {/*        <i className="bi bi-menu-button-wide"/><span>Transactions</span><i className="bi bi-chevron-down ms-auto"/>*/}
                    {/*    </a>*/}
                    {/*    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">*/}
                    {/*        <li>*/}
                    {/*            <Link to="/transactions">*/}
                    {/*                <i className="bi bi-circle"/><span>All Transactions</span>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</li>*/}

                </ul>

            </aside>

            <main id="main" className="main">
                {props.children}
            </main>

            <footer id="footer" className="footer">
                <div className="copyright">
                    &copy; Copyright <strong><span>Xpensave</span></strong>. All Rights Reserved
                </div>
            </footer>

            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a>
        </>
    )
}