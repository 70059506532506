import React, {useState} from "react";
import {Link} from "react-router-dom";
import {showMessage} from "../../util/util";
import {serverRequest} from "../../util/server_request";
import {Spin} from "antd";
import secureLocalStorage from "react-secure-storage";

export const Login = () => {

    const [form, setForm] = useState({email: null, password: null})
    const [loading, setLoading] = useState(false)

    const onChange = (e) => {
      setForm({
          ...form,
          [e.target.name] : e.target.value
      })
    }

    const submitLogin = () => {
        if (form.email && form.password){
            setLoading(true)
            serverRequest('POST','login', form).then(result => {
                if (result.error){
                    setLoading(false)
                    showMessage('error', result.message, 'Validation Error')
                }else{
                    setLoading(false)
                    secureLocalStorage.setItem('user', result.data)
                    secureLocalStorage.setItem('token', result.access_token)
                    window.location.href = "/";
                }
            })
        }else{
            showMessage('error', 'All fields required!', 'Validation Error')
        }
    }

    return (
        <main>
            <div className="container">
                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex justify-content-center py-4">
                                    <a href="#" className="logo d-flex align-items-center w-auto">
                                        <img src="assets/img/logo.png" alt=""/>
                                        <span className="d-none d-lg-block">Xpansave</span>
                                    </a>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                        </div>
                                        <Spin spinning={loading}>
                                            <div className="row g-3 needs-validation">
                                                <div className="col-12">
                                                    <label htmlFor="yourUsername" className="form-label">Email</label>
                                                    <div className="input-group has-validation">
                                                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                        <input type="email" name="email" className="form-control" id="yourUsername" required onChange={onChange}/>
                                                        <div className="invalid-feedback">Please enter your username.</div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <label htmlFor="yourPassword" className="form-label">Password</label>
                                                    <input type="password" name="password" className="form-control" id="yourPassword" required onChange={onChange}/>
                                                    <div className="invalid-feedback">Please enter your password!</div>
                                                </div>

                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" type="submit" onClick={submitLogin}>Login</button>
                                                </div>
                                                <div className="col-12">
                                                    <p className="small mb-0">Don't have account? <Link to="/register">Create an account</Link></p>
                                                </div>
                                            </div>
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    );
}