import React from "react";
import {Bar} from "react-chartjs-2";
import dayjs from 'dayjs';

export const BarChart = ({ barData }) => {

    const parseLabels = (filter, data) => {
        if (!data) return null;
        return Object.keys(data).map(dateString => {
            const date = dayjs(dateString);
            if (filter === 'daily') {
                return date.format('ddd');  // Mon, Tue, Wed, etc.
            } else if (filter === 'weekly') {
                return `Week of ${date.format('MMM D, YYYY')}`;  // e.g., "Week of Sep 23, 2024"
            } else if (filter === 'monthly') {
                return date.format('MMMM');  // e.g., September, October
            }
            return dateString;
        });
    };

    // Function to generate random hex color
    const getRandomColor = () => { return `#${Math.floor(Math.random() * 16777215).toString(16)}`; };

    // console.log(barData?.data)
    const labels = parseLabels(barData?.filter, barData?.data);
    const values = !barData?.data ? [] : Object.values(barData?.data)

    const randomColors = values.map(() => getRandomColor());

    const data = {
        labels: labels,
        datasets: [
            {
                label: `${barData?.filter} ${barData?.type}`,
                backgroundColor: randomColors,
                // borderColor: ["#E8F0FE", "#ffc5d4"],
                data: values,
            },
        ],
    };

  return <Bar data={data} />
}