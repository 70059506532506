import React, {useEffect,useState} from "react";
import {serverRequest} from "../../util/server_request";
import {Spin} from "antd";
import {getCurrency, showMessage} from "../../util/util";
import secureLocalStorage from "react-secure-storage";

export const ProfilePage = () => {

    const [currency, setCurrency] = useState(getCurrency)
    const [loader, setLoader] = useState(false);


    function updateCurrency() {
        if (currency){
            setLoader(true)
            serverRequest('PUT', 'update-profile', {currency: currency}).then(result => {
                setLoader(false)
                if (result.success){
                    secureLocalStorage.setItem('user', result.data)
                    showMessage('success', 'Currency updated!', 'Success')
                }
                console.log(result)
            })
        }else{
            showMessage('error', 'Set your currency!', 'Validation Error')
        }
    }

    return (
      <div className="card">
          <div className="card-body">
              <h5 className="card-title">Update Profile</h5>
              <div className="mt-5">
                  <div className="row">
                      <Spin spinning={loader}>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                              <h5>Update Currency</h5>
                              <div className='mt-5'>
                                  <label htmlFor="currency" className="form-label">Currency</label>
                                  <select className='form-control' id='currency' name='currency' onChange={e => setCurrency(e.target.value)} defaultValue={getCurrency()}>
                                      <option selected disabled={true}>--- select currency ---</option>
                                      <option value="$">Dollar $</option>
                                      <option value="€">Euro €</option>
                                      <option value="£">Pound Sterling £</option>
                                      <option value="₵">Ghana Cedis ₵</option>
                                      <option value="₦">Naira ₦</option>
                                  </select>
                              </div>
                              <button className="btn btn-primary mt-4" onClick={updateCurrency}>Update Currency</button>
                          </div>
                      </Spin>
                  </div>
              </div>
          </div>
      </div>
  )
}