//sample request to make
import base_url from "./baseUrl";
import {getUserToken, logout} from "./util";

export let serverRequest = (type, param, data) => {

    let URL = base_url;

    return new Promise((resolve, reject) => {
        fetch(URL+param,{
            method: type,
            headers:{
                // Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getUserToken()}`,
                'Accept': "application/json"
            },
            body: JSON.stringify(data),

        })
            .then((response) => {
                if (response.status === 401){
                    logout()
                }
                return response.json()
            })
            .then((resp) => {
                resolve(resp)

            })
            .catch((error) => {
                reject(error)
            }).finally(()=>{
                // notifyUser('error','Sorry problem getting data','Error')
        })
    });
}
