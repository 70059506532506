import React, {useEffect} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {getUserToken} from "./util/util";
import {Login} from "./Pages/Authentication/login";
import {Register} from "./Pages/Authentication/register";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import {AuthenticatedRoutes} from "./Snippets/authenticatedRoutes";

// Component to handle protected routes
const PrivateRoute = ({ children }) => {
    const token = getUserToken();
    return token ? children : <Navigate to="/login" />;
}

const App = () => {
  return (
      <Router>
          <ProgressBarHandler />
          <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />

              {/* Protected Routes - Wrapped with PrivateRoute */}
              <Route path="*" element={
                  <PrivateRoute>
                      <AuthenticatedRoutes />
                  </PrivateRoute>
              } />
          </Routes>
      </Router>
  );
}

// ProgressBarHandler Component to handle NProgress
const ProgressBarHandler = () => {
    const location = useLocation();

    useEffect(() => {
        NProgress.start();
        NProgress.done();
        return () => { NProgress.done(); };
    }, [location]);
    return null;
};

export default App;