import React, {useEffect, useState} from "react";
import {serverRequest} from "../util/server_request";
import {showMessage} from "../util/util";
import {Spin} from "antd";

export const NewTransaction = (props) => {

    const [categories, setCategories] = useState([])
    const [form, setForm] = useState({
        category: null, amount: 0, type: null, date: null
    })
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        serverRequest('GET','categories').then(result => {
            if (result){
                setCategories(result.data)
            }
        })

        return () => controller.abort();
    },[])


    const onChange = (e) => {
      setForm({
          ...form,
          [e.target.name]: e.target.value
      })
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
      if (form.amount && form.type && form.date && form.category){
          setLoading(true)
          serverRequest('POST', `transactions/category/${form.category}`, form).then(result => {
              if (result){
                  setForm(null)
                  props.onFetch()
                  setLoading(false)
                  props.onClose()
              }
          })
      }else{
          showMessage('error', 'All fields required!', 'Validation Error')
      }
    }

  return (
      <Spin spinning={loading}>
          <form>
              <div className="form-group mt-4">
                  <label htmlFor="category">Transaction Category</label>
                  <select className="form-control" name='category' id='category' onChange={onChange}>
                      <option selected={true} disabled={true} id={'322323'}>--- Select Category ---</option>
                      {categories.map(item => <option key={item.id} id={item.id} value={item.id}>{item.name}</option>)}
                  </select>
              </div>

              <div className="form-group mt-4">
                  <label htmlFor="amount">Amount</label>
                  <input type="number" className="form-control" name='amount' id='amount'  onChange={onChange}/>
              </div>

              <div className="form-group mt-4">
                  <label htmlFor="type">Transaction Type</label>
                  <select className="form-control" name='type' id='type' onChange={onChange}>
                      <option disabled={true} selected={true}>--- Select type ---</option>
                      <option value="expense">Expense</option>
                      <option value="income">Income</option>
                  </select>
              </div>

              <div className="form-group mt-4">
                  <label htmlFor="date">Date</label>
                  <input type="date" className="form-control" name='date' id='date' onChange={onChange}/>
              </div>

              <button className="btn btn-primary w-100 mt-5" onClick={onSubmitForm}>Add Transaction</button>
          </form>
      </Spin>
  )
}