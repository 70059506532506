import React from "react";

export const Pagination = ({ currentPage, perPage, total, onPageClick }) => {
    const totalPages = Math.ceil(total / perPage);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) { pageNumbers.push(i); }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link" onClick={() => {if (currentPage > 1) onPageClick(currentPage - 1);}} disabled={currentPage === 1}>
                        Previous
                    </button>
                </li>

                {pageNumbers.map(pageNumber => (
                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => onPageClick(pageNumber)}>
                            {pageNumber}
                        </button>
                    </li>
                ))}

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link" onClick={() => { if (currentPage < totalPages) onPageClick(currentPage + 1); }} disabled={currentPage === totalPages}>
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
};