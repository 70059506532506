import React, {useEffect,useState} from "react";
import {serverRequest} from "../../util/server_request";
import {Drawer, Spin} from "antd";
import {NewTransaction} from "../../Components/NewTransaction";
import {Pagination} from "../../Components/Pagination";
import {Transactions} from "../../Components/Transactions";

export const TransactionPage = () => {

    const [transactions, setTransactions] = useState([])
    const [transLoader, setTransLoading] = useState(false);
    const [meta, setMeta] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        fetchTransactions()
    }, [])

    const fetchTransactions = (page = 1) => {
        setTransLoading(prevState => !prevState)
        serverRequest('GET',`transactions?page=${page}`).then(result => {
            setTransactions(result.data)
            setMeta(result.meta);
            setTransLoading(prevState => !prevState)
        })
    }

  return (
      <div className="card">
          <div className="card-body">
              <div className="row align-items-center">
                  <div className="col-10">
                      <h5 className="card-title">All Transactions</h5>
                  </div>
                  <div className="col-2">
                      <button className="btn btn-circle" onClick={() => setDrawerOpen(prevState => !prevState)}>New <i className="bi bi-plus-circle"/></button>
                  </div>
              </div>
              <Spin spinning={transLoader}>
                  <Transactions transactions={transactions}/>
                  {transactions.length > 0 ?
                      <Pagination
                          currentPage={meta.current_page}
                          perPage={meta.per_page}
                          total={meta.total}
                          onPageClick={fetchTransactions}/>
                      : ''}
              </Spin>
          </div>
          <Drawer title="Add new Transaction" onClose={() => setDrawerOpen(prevState => !prevState)} open={drawerOpen} width={500} destroyOnClose={true}>
              <NewTransaction onFetch={()=> fetchTransactions()} onClose={() => setDrawerOpen(prevState => !prevState)} />
          </Drawer>
      </div>
  )
}