import React, {useEffect, useState} from "react";
import {Transactions} from "../../Components/Transactions";
import {serverRequest} from "../../util/server_request";
import {Drawer, Spin} from "antd";
import {NewTransaction} from "../../Components/NewTransaction";
import Chart from "chart.js/auto";
import {BarChart} from "../../Components/BarChart";
import {PieChart} from "../../Components/PieChart";
import {Pagination} from "../../Components/Pagination";
import {getCurrency} from "../../util/util";

export const Home = () => {

    const [transactions, setTransactions] = useState([])
    const [summary, setSummary] = useState(null)
    const [pieData, setPieData] = useState(null)
    const [barData, setBarData] = useState(null)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [summaryLoader, setSummaryLoading] = useState(false);
    const [transLoader, setTransLoading] = useState(false);
    const [pieLoader, setPieLoading] = useState(false);
    const [barLoader, setBarLoading] = useState(false);
    const [meta, setMeta] = useState({});


    useEffect(() => {
        initLoaders();
    },[])

    const initLoaders = () => {
        fetchTransactions()
        fetchSummary()
        fetchSummaryPieChart()
        fetchSummaryBarChart()
    }

    const fetchTransactions = (page = 1) => {
        setTransLoading(prevState => !prevState)
      serverRequest('GET',`transactions?page=${page}`).then(result => {
          setTransactions(result.data)
          setMeta(result.meta);
          setTransLoading(prevState => !prevState)
      })
    }

    const fetchSummary = () => {
        setSummaryLoading(prevState => !prevState)
      serverRequest('GET','dashboard').then(result => {
          setSummary(result)
          setSummaryLoading(prevState => !prevState)
      })
    }

    const fetchSummaryPieChart = () => {
        setPieLoading(prevState => !prevState)
      serverRequest('GET','statistics/pie-chart').then(result => {
          setPieData(result)
          setPieLoading(prevState => !prevState)
      })
    }

    const fetchSummaryBarChart = (url='statistics') => {
        //?type=income&filter=daily
        setBarLoading(prevState => !prevState)
      serverRequest('GET',url).then(result => {
          setBarData(result)
          setBarLoading(prevState => !prevState)
      })
    }

  return (
      <section className="section">
          <div className="row">
              <div className="col-lg-6">
                  <Spin spinning={summaryLoader}>
                      <div className="row">
                          <div className="col-xxl-4 col-md-4 col-lg-4">
                              <div className="card">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center">
                                          <div
                                              className="card-icon-purple rounded-circle d-flex align-items-center justify-content-center">
                                              <i className="bi bi-wallet"/>
                                          </div>
                                          <div className="ps-3">
                                              <p className='mb-0 small-summary-head'>Balance</p>
                                              <p className='mb-0 small-summary-amt'>{`${getCurrency()}${summary?.total_balance}`}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xxl-4 col-md-4 col-lg-4">
                              <div className="card">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center">
                                          <div
                                              className="card-icon-blue rounded-circle d-flex align-items-center justify-content-center">
                                              <i className="bi bi-arrow-down-left"/>
                                          </div>
                                          <div className="ps-3">
                                              <p className='mb-0 small-summary-head'>Income</p>
                                              <p className='mb-0 small-summary-amt'>{`${getCurrency()}${summary?.total_income}`}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xxl-4 col-md-4 col-lg-4">
                              <div className="card">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center">
                                          <div
                                              className="card-icon-red rounded-circle d-flex align-items-center justify-content-center">
                                              <i className="bi bi-arrow-up-right"/>
                                          </div>
                                          <div className="ps-3">
                                              <p className='mb-0 small-summary-head'>Expense</p>
                                              <p className='mb-0 small-summary-amt'>{`${getCurrency()}${summary?.total_expenses}`}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </Spin>
                  <div className="card card-grey">
                      <div className="card-body">
                          <div className="align-items-center">
                              <div className="d-flex align-items-center">
                                  <div className="ps-3">
                                      <p className='mb-0 small-summary-head'>Credit Score</p>
                                  </div>
                                  <div className={`${summary?.creditScore < 50 ? 'card-icon-red': 'card-icon-purple'} rounded-circle d-flex align-items-center justify-content-center`}>{summary?.creditScore}</div>
                              </div>
                              <div className='pie-cart-container' align='center'>
                                  <Spin spinning={pieLoader}>
                                      <PieChart pieData={pieData} />
                                  </Spin>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="card card-grey">
                      <div className="card-body">
                          <div className="filter">
                              <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"/></a>
                              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                  <li className="dropdown-header text-start"><h6>Filter By Income</h6></li>
                                  <li><a className="dropdown-item" href="#" onClick={() => fetchSummaryBarChart('statistics?filter=daily&type=income')}>Daily</a></li>
                                  <li><a className="dropdown-item" href="#" onClick={() => fetchSummaryBarChart('statistics?filter=weekly&type=income')}>Weekly</a></li>
                                  <li><a className="dropdown-item" href="#" onClick={() => fetchSummaryBarChart('statistics?filter=monthly&type=income')}>Monthly</a></li>
                                  <li className="dropdown-header text-start"><h6>Filter By Expense</h6></li>
                                  <li><a className="dropdown-item" href="#" onClick={() => fetchSummaryBarChart('statistics?filter=daily&type=expense')}>Daily</a></li>
                                  <li><a className="dropdown-item" href="#" onClick={() => fetchSummaryBarChart('statistics?filter=weekly&type=expense')}>Weekly</a></li>
                                  <li><a className="dropdown-item" href="#" onClick={() => fetchSummaryBarChart('statistics?filter=monthly&type=expense')}>Monthly</a></li>

                              </ul>
                          </div>
                          <div className='' align='center'>
                              <Spin spinning={barLoader}>
                                  <BarChart barData={barData} />
                              </Spin>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="col-lg-6">
                  <div className="card">
                      <div className="card-body">
                          <div className="row align-items-center">
                              <div className="col-10">
                                  <h5 className="card-title">Transactions History</h5>
                              </div>
                              <div className="col-2">
                                  <button className="btn btn-circle" onClick={() => setDrawerOpen(prevState => !prevState)}>New <i className="bi bi-plus-circle"/></button>
                              </div>
                          </div>
                          <Spin spinning={transLoader}>
                              <Transactions transactions={transactions}/>
                              {transactions.length > 0 ?
                                  <Pagination
                                      currentPage={meta.current_page}
                                      perPage={meta.per_page}
                                      total={meta.total}
                                      onPageClick={fetchTransactions}/>
                                  : ''}
                          </Spin>
                      </div>
                  </div>
              </div>
          </div>
          <Drawer title="Add new Transaction" onClose={() => setDrawerOpen(prevState => !prevState)} open={drawerOpen} width={500} destroyOnClose={true}>
              <NewTransaction onFetch={()=> initLoaders()} onClose={() => setDrawerOpen(prevState => !prevState)} />
          </Drawer>
      </section>
  )
}
